// headerController.js

export const handleScroll = (setIsFloating) => {
    const onScroll = () => {
      setIsFloating(window.scrollY > 0);
    };
  
    window.addEventListener('scroll', onScroll);
  
    // Clean up event listener on component unmount
    return () => window.removeEventListener('scroll', onScroll);
  };
  
  export const toggleMenu = (isMenuOpen, setIsMenuOpen) => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  export const scrollToSection = (sectionId, setIsMenuOpen) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 90,
        behavior: 'smooth',
      });
    }
    setIsMenuOpen(false);
  };
  
  export const openGallery = () => {
    const galleryUrl = "https://www.facebook.com/media/set/?set=a.2326243247659554&type=3";
    window.open(galleryUrl, "_blank", "noopener,noreferrer");
  };
  