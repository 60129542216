import React from 'react';
import classes from './UnderConstruction.module.css';

const UnderConstruction = () => {
  return (
    <div className={classes.container}>
      <div className={classes.constructionContainer}>
        <div className={classes.sign}>
          <div className={classes.error}>
            <span>404</span>
            <span>ERROR</span>
          </div>
          <div className={`${classes.bar} ${classes.topBar}`}></div>
          <div className={`${classes.bar} ${classes.bottomBar}`}></div>
        </div>
        <div className={classes.stopSign}></div>
        <div className={classes.cone}></div>
      </div>
      <p className={classes.message}>We're enhancing our website to make it easier for you to explore and find the travel destinations you love. Please check back soon!</p>
    </div>
  );
};

export default UnderConstruction;
