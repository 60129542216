import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './screens/Homepage/Homepage';
import TermsOfUse from './screens/TermsOfUse/TermsOfUse';
import GeneralTermsConditions from './screens/GeneralTermsConditions/GeneralTermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import UnderConstruction from './screens/UnderConstruction/UnderConstruction';
import NotFound from './screens/NotFound/NotFound';
import { ENVIRONMENT } from './utils/constants';

const AppRoutes = ({ isSidebarOpen, toggleSidebar }) => {  
  return (
    <Routes>
      {/* Public routes (not protected) */}
      <Route 
        path="/" 
        element={ENVIRONMENT === 'production' ? <Homepage /> : <UnderConstruction />} 
      />

      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/terms-and-conditions" element={<GeneralTermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* Fallback route for unknown paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
